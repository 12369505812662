import { PayloadAction } from '@reduxjs/toolkit';
import PaymentPageRequests, { KeyValuePair, PaymentPayload, PaymentToken } from 'app/containers/PaymentPage/types';
import _ from 'lodash';
import { createSlice } from 'utils/@reduxjs/toolkit';

// The initial state of the sourceOfFunds container
export const initialState: PaymentPageRequests = {
    ModalStatus             : 'close',
    FrameStatus             : 'close',
    IntentStage             : 'main_panel',
    ActiveAPILink           : '',
    FormType                : '',
    MCCRequestType          : '',
    ModalStart              : false,
    FrameStart              : false,
    EndTransaction          : false,
    MCCPayloadFetched       : false,
    MCCResultFetched        : false,
    XenditPayloadFetched    : false,
    XenditResultFetched     : false,
    AllBankQRPHStatus       : false,
    IntentInitiate          : false,
    ModalAPIPayloadFetched  : false,
    ModalAPIResultFetched   : false,
    KYCInitiate             : false,
    KYCFetched              : false,
    UploadingInProgress     : false,
    OTPValidation           : false,
    OTPInititate            : false,
    KYCCookieExists         : true,
    ClientValidating        : false,
    ModalStack              : [],
    PreviousValue           : {},
    APIAction               : {},
    InitialSetup            : {},
    IntentResult            : {},
    IntentPayload           : {},
    ModalAPIPayload         : {},
    ModalAPIResult          : {},
    ClientInformation       : {},
    DisableButton           : {},
    ClientStatus            : {},
    AllBankQRPHResponse     : {},
    CardDetails             : {},
    BINLookup               : {},
    PaymentPayload          : {
        amount_details      : {
            gross       : 0,
            net         : 0,
            currency    : 'PHP',
            fees        : {
                system              : 10,
                sms                 : 0,
                international_card  : 0,
            },
        },
        receiver_details    : {
            partner_info: {
                username: '',
            },
        },
    },
    InformationMessage      : {
        header  : '',
        message : '',
    },
    AdvisoryMessage         : {
        header  : '',
        message : '',
    },
};

const paymentPageRequestSlice = createSlice({
    name: 'paymentPageRequest',
    initialState,
    reducers: {
        /**
         * Identifies if Modal is `open` or `close`
         */
        modalStatus(state, action: PayloadAction<string>) {
            state.ModalStatus = action.payload;
        },
        /**
         * Identifies if Frame is `open` or `close`
         */
        frameStatus(state, action: PayloadAction<string>) {
            state.FrameStatus = action.payload;
        },
        /**
         * Initiates Modal Start
         */
        modalStart(state, action: PayloadAction<boolean>) {
            state.ModalStart = action.payload;
        },
        /**
         * Initiates Frame Start
         */
        frameStart(state, action: PayloadAction<boolean>) {
            state.FrameStart = action.payload;
        },
        /**
         * Ends transaction by showing status page
         */
        endTransaction(state, action: PayloadAction<boolean>) {
            state.EndTransaction = action.payload;
        },
        /**
         * Identifies current intent stage
         */
        updateIntentStage(state, action: PayloadAction<string>) {
            state.IntentStage = action.payload;

            switch (action.payload) {
                case 'notification_details_modal':
                    state.OTPValidation = false;
                break;
            }
        },
        /**
         * Stores previous value to cross-validate changed value
         */
        storePreviousValue(state, action: PayloadAction<object>) {
            state.PreviousValue = _.merge(state.PreviousValue, action.payload);
        },
        /**
         * Stores API actions
         */
        storeAPIActions(state, action: PayloadAction<object>) {
            state.APIAction = _.merge(state.APIAction, action.payload);
        },
        /**
         * Identifies active API URL
         */
        activeAPILink(state, action: PayloadAction<string | object>) {
            state.ActiveAPILink = action.payload;
        },
        /**
         * Disables a button's state while other Component is running
         */
        disableButton(state, action: PayloadAction<any>) {
            switch (action.payload) {
                case 'clear': state.DisableButton = []; break;
                default: state.DisableButton = action.payload;
            }
        },
        /**
         * Stores initial parameters required for Users
         */
        storeInitialSetup(state, action: PayloadAction<object>) {
            if ('key' in action.payload) {
                const [last, ...paths] = (action.payload['key'] as any).split('.').reverse();

                state.InitialSetup = _.merge(state.InitialSetup, paths.reduce((acc, el) => ({ [el]: acc }), { [last]: action.payload['value'] }));
            } else {
                state.InitialSetup = _.merge(state.InitialSetup, action.payload);
            }
        },
        /**
         * Stores tracking information of Users
         */
        storeTrackingInformation(state, action: PayloadAction<object>) {
            if ('key' in action.payload) {
                const [last, ...paths] = (action.payload['key'] as any).split('.').reverse();

                state.TrackingInformation = _.merge(state.TrackingInformation, paths.reduce((acc, el) => ({ [el]: acc }), { [last]: action.payload['value'] }));
            } else {
                state.TrackingInformation = _.merge(state.TrackingInformation, action.payload);
            }
        },
        /**
         * Stores Users Information
         */
        storeRecipientInformation(state, action: PayloadAction<object>) {
            if ('key' in action.payload) {
                const [last, ...paths] = (action.payload['key'] as any).split('.').reverse();

                state.RecipientInformation = _.merge(state.RecipientInformation, paths.reduce((acc, el) => ({ [el]: acc }), { [last]: action.payload['value'] }));
            } else {
                state.RecipientInformation = _.merge(state.RecipientInformation, action.payload);
            }
        },
        /**
         * Stores Modal Stack 
         */
        storeModalStack(state, action: PayloadAction<any>) {
            switch (action.payload) {
                case 'clear': state.ModalStack = []; break;
                case 'pop': state.ModalStack.pop(); break;
                default:
                    if (action.payload[0]?.includes('-')) {
                        let payload = action.payload[0].split('-').reverse();

                        state.FormType = payload[0];

                        if (!state.ModalStack.includes(payload[1])) {
                            state.ModalStack = [...state.ModalStack, ...[payload[1]]].filter((v, i, a) => a.indexOf(v) === i).filter(n => n);
                        }
                    } else {
                        state.FormType      = '';
                        state.ModalStack    = [...state.ModalStack, ...action.payload].filter((v, i, a) => a.indexOf(v) === i).filter(n => n);
                    }
            }
        },
        /**
         * Return to Modal to the given Modal Name 
         */
        redirectModalStack(state, action: PayloadAction<any>) {
            const modalTarget = state.ModalStack.indexOf(action.payload);

            state.ModalStack.splice(modalTarget + 1);
        },
        /**
         * Sets Information Modal details. Could be for warning, alert and/or info purposes..
         */
        setInformationMessage(state, action: PayloadAction<object | undefined>) {
            if (state.InformationMessage && action.payload) {
                state.InformationMessage = action.payload;
            }
        },
        /**
         * Sets Advisory Frame details. Could be for warning, alert and/or info purposes..
         */
        setAdvisoryMessage(state, action: PayloadAction<object | string | undefined>) {
            if (state.AdvisoryMessage) {
                switch (action.payload) {
                    case 'clear':
                        state.AdvisoryMessage['header']     = '';
                        state.AdvisoryMessage['message']    = '';
                    break;
                    default:
                        state.AdvisoryMessage['header']     = action.payload?.['header'] || '';
                        state.AdvisoryMessage['message']    = action.payload?.['message'] || '';
                }
            }
        },
        /**
         * Fetched query parameters
         */
        getQueryParameter(state, action: PayloadAction<object>) {
            state.QueryParameters = action.payload;
        },
        /**
         * Fetches announcement banner
         */
        getAnnouncementBanner(state, action: PayloadAction<object | string | undefined>) {
            switch (action.payload) {
                case 'hide':
                    state.AnnouncementInfo = _.merge(state.AnnouncementInfo, {hidden: true});
                break;
                default:
                    state.AnnouncementInfo = action.payload;
            }
        },
        /**
         * Fetches BIN Code Lookup
         */
        getBINLookup(state, action: PayloadAction<object | undefined>) {
            state.BINLookup = action.payload;
        },
        /**
         * Stores parameters needed for the transation
         */
        storePaymentPayload(state, action: PayloadAction<PaymentPayload | KeyValuePair>) {
            if ('key' in action.payload) {
                const [last, ...paths] = action.payload['key'].split('.').reverse();

                if ('replace' in action.payload) {
                    let newObject           = paths.reduce((acc, el) => ({ [el]: acc }), { [last]: action.payload['value'] }),
                        sourceForReplace    = {},
                        valueChecker        = (objectValue, sourceValue) => {
                            if (_.isObject(objectValue) && _.isObject(sourceValue)) {
                                let parsedObjectValue = JSON.parse(JSON.stringify(objectValue));

                                if ((action.payload['replace'] in parsedObjectValue) && (action.payload['replace'] in sourceValue)) {
                                    sourceForReplace = sourceValue[action.payload['replace']];
                                }

                                if (sourceValue == sourceForReplace) {
                                    return sourceForReplace;
                                }

                                return _.mergeWith(parsedObjectValue, sourceValue, valueChecker);
                            }

                            return sourceValue;
                        };

                    state.PaymentPayload = _.mergeWith({}, state.PaymentPayload, newObject, valueChecker);
                } else {
                    state.PaymentPayload = _.merge(state.PaymentPayload, paths.reduce((acc, el) => ({ [el]: acc }), { [last]: action.payload['value'] }));
                }
            } else {
                // Special case object reset
                if (!state.EndTransaction) {
                    if (action.payload['source_of_funds'] && action.payload['source_of_funds']['sof_info']) {
                        if (state.PaymentPayload?.['source_of_funds'] && state.PaymentPayload?.['source_of_funds']['sof_info']) {
                            delete state.PaymentPayload?.['source_of_funds']['sof_info'];
                        }
                    }
                }

                state.PaymentPayload = _.merge(state.PaymentPayload, action.payload);
            }
        },
        /**
         * Stores file upload to S3 Bucket
         */
        storeFileUploadToS3(state, action: PayloadAction<any>) {
            if ('sender_details' in action.payload) {
                state.UploadingInProgress   = false;
                state.PaymentPayload        = _.merge(state.PaymentPayload,  action.payload);

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.UploadingInProgress = true;
            }
        },
        /**
         * Computes Fees everytime `fees` has an update
         */
        computeFees(state) {
            if (state.PaymentPayload) {
                state.PaymentPayload['amount_details']['net']   = parseFloat(state.PaymentPayload['amount_details']['net'].toString().replace(',', ''));
                state.PaymentPayload['amount_details']['gross'] = parseFloat(state.PaymentPayload['amount_details']['gross'].toString().replace(',', ''));

                switch (state.PaymentPayload['amount_details']['fees']['receiving']?.['type']) {
                    case 'percentage':
                        state.PaymentPayload['amount_details']['fees']['receiving']['fee'] = (state.PaymentPayload['amount_details']['gross'] || 1) * (state.PaymentPayload['amount_details']['fees']['receiving']['initial'] / 100);
                    break;
                }

                switch (state.PaymentPayload['amount_details']['fees']['sending']?.['type']) {
                    case 'percentage':
                        let computedSendingFee  = (state.PaymentPayload['amount_details']['gross'] || 1) * (state.PaymentPayload['amount_details']['fees']['sending']['initial'] / 100),
                            clientsAsNonPartner = ['gas_station', 'water_utilities'];

                        switch (state.RecipientInformation?.['accountType']) {
                            case 'client':
                                if (!clientsAsNonPartner.some(word => state.PaymentPayload?.['receiver_details']['partner_info']['group'].includes(word))) {
                                    if (state.PaymentPayload?.['source_of_funds']['payment_method'] == 'mastercard_visa') {
                                        let remainder = computedSendingFee % 10;

                                        if (remainder) {
                                            computedSendingFee = (computedSendingFee - remainder) + 10;
                                        }
                                    }
                                }
                            break;
                        }

                        state.PaymentPayload['amount_details']['fees']['sending']['fee'] = computedSendingFee;

                        switch (state.PaymentPayload['amount_details']['audit']['merchant_discount_rate']['type']) {
                            case 'percentage':
                                state.PaymentPayload['amount_details']['audit']['merchant_discount_rate']['fee'] = (state.PaymentPayload['amount_details']['gross'] || 1) * (state.PaymentPayload['amount_details']['audit']['merchant_discount_rate']['initial'] / 100);
                            break;
                        }

                        switch (state.PaymentPayload['amount_details']['audit']['jpt_profit']['merchant_discount_rate']['type']) {
                            case 'percentage':
                                state.PaymentPayload['amount_details']['audit']['jpt_profit']['merchant_discount_rate']['fee'] = (state.PaymentPayload['amount_details']['gross'] || 1) * (state.PaymentPayload['amount_details']['audit']['jpt_profit']['merchant_discount_rate']['initial'] / 100);
                            break;
                        }
                    break;
                }

                switch (state.RecipientInformation?.['accountType']) {
                    case 'individual': case 'business':
                        switch (state.PaymentPayload['amount_details']['fees']['witholding_tax']['type']) {
                            case 'percentage':
                                state.PaymentPayload['amount_details']['fees']['witholding_tax']['fee'] = (state.PaymentPayload['amount_details']['gross'] || 1) * (state.PaymentPayload['amount_details']['fees']['witholding_tax']['initial'] / 100);
                            break;
                            default:
                                state.PaymentPayload['amount_details']['fees']['witholding_tax']['fee'] = state.PaymentPayload['amount_details']['fees']['witholding_tax']['initial'];
                        }
                    break;
                }

                if ((state.CardDetails?.['cardCountry'] ? state.CardDetails?.['cardCountry'].split(' - ')[0] : 'PH') != 'PH') {
                    state.PaymentPayload['amount_details']['fees']['international_card'] = (state.PaymentPayload['amount_details']['gross'] || 1) * .01;
                } else {
                    state.PaymentPayload['amount_details']['fees']['international_card'] = 0;
                }

                let {fees, gross} = state.PaymentPayload?.['amount_details'];
                let {sms, system, delivery, other_charges, receiving, sending, international_card} = fees;

                let totalDeliveryFee    = Object.values(delivery || {})?.reduce((a, b) => parseFloat(a as string) + parseFloat(b as string), 0) || 0,
                    totalOtherCharges   = Object.values(other_charges || {})?.reduce((a, b) => parseFloat(a as string) + parseFloat(b as string), 0) || 0,
                    totalFee            = system + (receiving?.fee || 0) + (sending?.fee || 0);

                switch (state.PaymentPayload['receiver_details']['account_plan']) {
                    case 'Premium':
                        switch (state.PaymentPayload['receiver_details']['options']['charge_fees_to']) {
                            case 'recipient':
                                switch (state.PaymentPayload['receiver_details']['partner_info']['group']) {
                                    case 'gas_station':
                                        state.PaymentPayload['amount_details']['net'] = gross + system + sms + international_card;
                                    break;
                                    case 'water_utilities':
                                        state.PaymentPayload['amount_details']['net'] = gross + sms + international_card;
                                    break;
                                    default:
                                        switch (state.RecipientInformation?.['accountType']) {
                                            case 'client':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges;
                                            break;
                                            case 'individual':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card;
                                            break;
                                            case 'donation':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card;
                                            break;
                                            case 'business':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalDeliveryFee + totalOtherCharges;
                                            break;
                                        }
                                }
                            break;
                            case 'sender':
                                totalFee = system + (sending?.fee || 0);

                                switch (state.PaymentPayload['receiver_details']['partner_info']['group']) {
                                    case 'gas_station':
                                        state.PaymentPayload['amount_details']['net'] = gross + system + sms + international_card;
                                    break;
                                    case 'water_utilities':
                                        state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges;
                                    break;
                                    default:
                                        switch (state.RecipientInformation?.['accountType']) {
                                            case 'client':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges;
                                            break;
                                            case 'individual':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges;
                                            break;
                                            case 'donation':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges;
                                            break;
                                            case 'business':
                                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges + totalDeliveryFee;
                                            break;
                                        }
                                }
                            break;
                        }
                    break;
                    default:
                        switch (state.PaymentPayload['receiver_details']['partner_info']['group']) {
                            case 'gas_station':
                                // Charged to sender
                                state.PaymentPayload['amount_details']['net'] = gross + system + sms + international_card;
                            break;
                            case 'water_utilities':
                                // Charged to sender
                                state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalFee + totalOtherCharges;
                            break;
                            default:
                                switch (state.RecipientInformation?.['accountType']) {
                                    case 'client':
                                        // Charged to sender
                                        state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalOtherCharges + totalFee;
                                    break;
                                    case 'individual':
                                        // Charged to recipient
                                        state.PaymentPayload['amount_details']['net'] = gross + sms + international_card;
                                    break;
                                    case 'donation':
                                        // Charged to sender
                                        state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalOtherCharges + totalFee;
                                    break;
                                    case 'business':
                                        // Charged to recipient
                                        state.PaymentPayload['amount_details']['net'] = gross + sms + international_card + totalDeliveryFee + totalOtherCharges;
                                    break;
                                }
                        }
                }
            }
        },
        /**
         * Stores the result of MCC based on the chosen SOF
         */
        storeMCCCheckoutSession(state, action: PayloadAction<object>) {
            state.MCCRequestType = 'initiate_session';

            if ('status_code' in action.payload) {
                state.MCCResult         = action.payload;
                state.MCCPayloadFetched = false;
                state.MCCResultFetched  = true;

                delete state.MCCPayload;

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.MCCPayload        = action.payload;
                state.MCCPayloadFetched = true;
                state.MCCResultFetched  = false;
            }
        },
        /**
         * Fetches MCC order details based on the retrieved info
         */
        fetchMCCRetrieveOrder(state, action: PayloadAction<object>) {
            state.MCCRequestType = 'retrieve_order';

            if ('processor' in action.payload) {
                state.MCCPayloadFetched = false;
                state.MCCResultFetched  = true;
                state.MCCResult         = action.payload;

                delete state.MCCPayload;

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.MCCPayloadFetched = true;
                state.MCCResultFetched  = false;
                state.MCCPayload        = action.payload;
                state.MCCResult         = {};
            }
        },
        /**
         * Stores the result of MCC based on the chosen SOF
         */
        storeXenditCreateToken(state, action: PayloadAction<object>) {
            if ('success' in action.payload) {
                state.XenditResult          = action.payload;
                state.XenditPayloadFetched  = false;
                state.XenditResultFetched   = true;

                delete state.XenditPayload;

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.XenditPayload         = action.payload;
                state.XenditPayloadFetched  = true;
                state.XenditResultFetched   = false;
            }
        },
        /**
         * Get QRPH Status
         */
        getAllBankQRPHStatus(state, action: PayloadAction<object>) {
            if ('status' in action.payload) {
                state.AllBankQRPHStatus     = action.payload['status'] as boolean;
                state.AllBankQRPHResponse   = action.payload['data'];
            }
        },
        /**
         * Fetch Country Details
         */
        fetchCountryDetails(state, action: PayloadAction<object>) {},
        /**
         * Fetch Modal API
         */
        fetchModalAPI(state, action: PayloadAction<object>) {
            if ('status_code' in action.payload) {
                state.ModalAPIPayloadFetched    = false;
                state.ModalAPIResultFetched     = true;
                state.ModalAPIResult            = action.payload;

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.ModalAPIPayloadFetched    = true;
                state.ModalAPIResultFetched     = false;
                state.ModalAPIPayload           = action.payload;
                state.ModalAPIResult            = {};
            }
        },
        /**
         * Reset Modal API
         */
        resetModalAPI(state, action: PayloadAction<boolean>) {
            state.ModalAPIPayloadFetched    = false;
            state.ModalAPIResultFetched     = false;
            state.ModalAPIResult            = {};

            if (action.payload) {
                state.ModalAPIPayload = {};
            }
        },
        /**
         * 
         */
        storeCardDetails(state, action: PayloadAction<object>) {
            state.CardDetails = action.payload;
        },
        /**
         * Store Intent Report
         */
        storeIntentReport(state, action: PayloadAction<object>) {
            state.MCCPayloadFetched         = false;
            state.MCCResultFetched          = false;
            state.XenditPayloadFetched      = false;
            state.XenditResultFetched       = false;
            state.ModalAPIPayloadFetched    = false;
            state.ModalAPIResultFetched     = false;
            state.ModalAPIResult            = {};

            if ('status_code' in action.payload) {
                state.IntentInitiate    = false;
                state.IntentPayload     = {};
                state.IntentResult      = action.payload;

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.IntentInitiate    = true;
                state.IntentPayload     = action.payload;
                state.IntentResult      = {};
            }

            delete state.MCCResult;
            delete state.XenditResult;
        },
        /**
         * Store Sender KYC Information
         */
        storeSenderKYC(state, action: PayloadAction<object>) {
            let formattedPayload = 'data' in action.payload ? _.mapKeys(action.payload['data'], (value, key) => _.camelCase(key)) : {};

            state.KYCInitiate   = !('status_code' in action.payload);
            state.KYCFetched    = 'status_code' in action.payload && (action.payload['status_code'] === 200);

            if ((state.KYCInformation && 'email' in state.KYCInformation) && Object.keys(formattedPayload).length && (state.KYCInformation['email'] == action.payload['data']['email'])) {
                state.KYCInformation = _.merge(state.KYCInformation, formattedPayload);
            } else {
                state.KYCInformation = formattedPayload;
            }

            if ('status_code' in action.payload && state.APIError) {
                state.APIError = {};
            }
        },
        /**
         * Resets OTP state for gracefull flow
         */
        resetOTPState(state, action: PayloadAction<boolean>) {
            state.OTPInititate = action.payload;
        },
        /**
         * Sends OTP to the User
         */
        sendOTP(state, action: PayloadAction<object | string>) {
            state.OTPInititate  = typeof action.payload === 'string';
            state.OTPExpiration = typeof action.payload === 'string' ? action.payload : '';
            state.OTPValidation = false;
        },
        /**
         * Validates OTP inputted by the User
         */
        validateOTP(state, action: PayloadAction<object | boolean>) {
            state.OTPInititate  = typeof action.payload === 'boolean';
            state.OTPExpiration = '';
            state.OTPValidation = typeof action.payload === 'boolean' ? action.payload : false;
        },
        /**
         * Checks active cookies for KYC
         */
        checkKYCCookie(state, action: PayloadAction<boolean>) {
            state.KYCCookieExists = action.payload;
        },
        /**
         * Check if callback has been processed
         */
        processCallback(state, action: PayloadAction<object>) {
            if ('target' in action.payload) {
                state.CallbackProcessed = false;
                state.CallbackResult    = {};
            } else {
                state.CallbackProcessed = true;
                state.CallbackResult    = action.payload;
            }
        },
        /**
         * Store Payment Information
         */
        storePaymentInformation(state, action: PayloadAction<object>) {
            if ('status_code' in action.payload) {
                state.PaymentInfoPayloadFetched = false;
                state.PaymentInfoResultFetched  = true;
                state.PaymentInfoPayload        = {};
                state.PaymentInfoResult         = action.payload;

                if (state.APIError) {
                    state.APIError = {};
                }
            } else {
                state.PaymentInfoPayloadFetched = true;
                state.PaymentInfoResultFetched  = false;
                state.PaymentInfoPayload        = action.payload;
                state.PaymentInfoResult         = {};
            }
        },
        /**
         * Store Client Information
         */
        setClientInformation(state, action: PayloadAction<object>) {
            state.ClientInformation = action.payload;
        },
        /**
         * Send Email/SMS Notification
         */
        sendNotification(state, action: PayloadAction<object>) {
            if (state.APIError) {
                state.APIError = {};
            }
        },
        /**
         * Fetch Payment Token
         */
        fetchPaymentToken(state, action: PayloadAction<string>) {
            state.PaymentToken = undefined
        },
        storePaymentToken(state, action: PayloadAction<PaymentToken>) {
            state.PaymentToken = action.payload
        },
        /**
         * Calculate Delivery Fee
         */
        calculateDeliveryFee(state, action: PayloadAction<object>) {},
        /**
         * Book order's delivery
         */
        bookDelivery(state, action: PayloadAction<string>) {},
        /**
         * Select Fulfillment Method
         */
        selectedFulfillmentMethod(state, action: PayloadAction<boolean>) {
            state.FulfillmentMethodSelected = action.payload
        },
        /**
         * Validating Client
         */
        isClientValid(state, action: PayloadAction<object>) {
            state.ClientStatus = action.payload;
        },
        validatingClient(state, action: PayloadAction<boolean>) {
            state.ClientValidating = action.payload
        },
        validateAutosweep(state, action: PayloadAction<any>) {
            // state.ClientValidating = true;
        },
        validateEasytrip(state, action: PayloadAction<any>) {
            // state.ClientValidating = true;
        },
        validateBeep(state, action: PayloadAction<any>) {
            // state.ClientValidating = true;
        },
        validateBayadcenter(state, action: PayloadAction<any>) {
            state.ClientValidating = true;
        },
        apiError(state, action: PayloadAction<object>) {
            state.APIError = action.payload;
        },
    },
});

export const {
    actions: paymentPageRequestActions,
    reducer,
    name: sliceKey,
} = paymentPageRequestSlice;
